import {IItemMenu, IMegaItem} from "@/interfaces/navigation";
import {
  HiOutlineArrowPath,
  HiOutlineBriefcase,
  HiOutlineBuildingOffice2,
  HiOutlineChartPie,
  HiOutlineCog8Tooth,
  HiOutlineDeviceTablet,
  HiOutlineIdentification,
  HiOutlineMapPin,
  HiOutlineRectangleStack,
  HiOutlineTruck,
  HiOutlineUser,
  HiOutlineUserGroup,
  HiOutlineUsers,
} from "react-icons/hi2";
import {AiOutlineSwap, AiOutlineTag} from "react-icons/ai";
import {APPLICATIONS} from "@scanow/config";

export function IsActive(pathname: String, item: IItemMenu): boolean {
  if (item.href === pathname) {
    return true;
  }

  var url = pathname.split("/");
  url.pop();
  var newUrl = url.join("/");
  console.log(newUrl);
  if (newUrl === item.href) {
    return true;
  }

  return false;
  // if (item.checkActive) {
  //   return item.checkActive(pathname, item);
  // }
  // return item?.exact
  //   ? pathname == item?.href
  //   : item?.href
  //   ? pathname.indexOf(item.href) === 0
  //   : false;
}

export function MainNavigation() {
  const menu: IItemMenu[] = [
    {
      href: "/admin/user/profile",
      icon: <HiOutlineUser className="w-6 h-6" />,
      name: "Mon profil",
      // description: t("navigation.private.dashboard"),
      exact: true,
      min_role: 3,
    },
    // {
    //   href: "/admin/user/stats",
    //   icon: <HiOutlineChartBarSquare className="w-6 h-6" />,
    //   name: "Mes statistiques",
    //   // description: t("navigation.private.dashboard"),
    //   exact: true,
    //   min_role: 3,
    // },
    {
      href: "/admin/settings",
      icon: <HiOutlineCog8Tooth className="w-6 h-6" />,
      name: "Paramètres",
      // description: t("navigation.private.dashboard"),
      exact: true,
      min_role: 3,
    },
    // {
    //   href: "/admin/help",
    //  icon: <HiOutlineLifebuoy className="w-6 h-6" />,
    //   name: "Aide",
    // description: t("navigation.private.dashboard"),
    //   exact: true,
    //   min_role: 3,
    // },
  ];

  return menu;
}

export function SidebarNavigation() {
  const menu: IItemMenu[] = [
    {
      href: "/admin/dashboard",
      //path: "/admin/dashboard",
      icon: <HiOutlineChartPie className="w-5 h-5" />,
      name: "Tableau de bord",
      // description: t("navigation.private.dashboard"),
      exact: true,
      min_role: 3,
    },
    {
      href: "/admin/company",
      icon: <HiOutlineBuildingOffice2 className="w-5 h-5" />,
      name: "Entreprises",
      min_role: 1,
    },
    {
      href: "/admin/team",
      icon: <HiOutlineUserGroup className="w-5 h-5" />,
      name: "Équipes",
      min_role: 2,
    },
    {
      href: "/admin/user",
      icon: <HiOutlineUsers className="w-5 h-5" />,
      name: "Utilisateurs",
      min_role: 2,
    },
    {
      href: "/admin/location",
      icon: <HiOutlineMapPin className="w-5 h-5" />,
      name: "Localités",
      min_role: 3,
    },
    {
      href: "/admin/category",
      icon: <HiOutlineRectangleStack className="w-5 h-5" />,
      name: "Catégories",
      min_role: 3,
    },
    {
      href: "/admin/item",
      icon: <HiOutlineDeviceTablet className="w-5 h-5" />,
      name: "Modèles d'équipement",
      min_role: 3,
    },
    {
      href: "/admin/stock",
      icon: <HiOutlineTruck className="w-5 h-5" />,
      name: "Stocks",
      min_role: 3,
    },
    {
      href: "/admin/movement",
      icon: <AiOutlineSwap className="w-5 h-5" />,
      name: "Mouvements",
      min_role: 3,
    },
    /*{
      href: "/admin/checking",
      icon: <IoScanSharp className="w-5 h-5" />,
      name: "Scan",
      min_role: 3,
    },*/
    {
      href: "/admin/service",
      icon: <HiOutlineIdentification className="w-5 h-5" />,
      name: "Services",
      min_role: 3,
      allowedApplications: [APPLICATIONS.Asset],
    },
    {
      href: "/admin/staff",
      icon: <HiOutlineBriefcase className="w-5 h-5" />,
      name: "Employés",
      min_role: 3,
      allowedApplications: [APPLICATIONS.Asset],
    },
    {
      href: "/admin/asset",
      icon: <HiOutlineArrowPath className="w-5 h-5" />,
      name: "Actifs",
      min_role: 3,
      allowedApplications: [APPLICATIONS.Asset],
    },
    {
      href: "/admin/asset_label",
      icon: <AiOutlineTag className="w-5 -h5" />,
      name: "Labels",
      min_role: 3,
      allowedApplications: [APPLICATIONS.Asset],
    },

  ];
  // .filter((r) => r.min_role >= auth.auth.sonar_role_weight); // pour min role : admin=1 manageur=2 agent=3, 1>2>3

  // //Si la company n'a pas la documentation
  // if (!auth.auth.licence?.options.documentation) {
  //   menu = menu.filter((r) => r.path != "/admin/documentation");
  // }
  // //Si la company n'a pas le controle
  // if (!auth.auth.licence?.options.control) {
  //   menu = menu.filter((r) => r.path != "/admin/control");
  // }
  return menu;
}

export const PRIVATE_FOOTER_LINKS: IMegaItem[] = [
  {
    title: "Dashboard",
    items: [
      { href: "#", slug: "my-page", name: "Installation" },
      { href: "#", slug: "my-page", name: "Release Notes" },
      { href: "#", slug: "my-page", name: "Upgrade Guide" },
      { href: "#", slug: "my-page", name: "Browser Support" },
      { href: "#", slug: "my-page", name: "Editor Support" },
      { href: "#", slug: "my-page", name: "Dark Mode" },
    ],
  },
  {
    title: "Statistiques",
    items: [
      { href: "#", slug: "my-page", name: "Design features" },
      { href: "#", slug: "my-page", name: "Prototyping" },
      { href: "#", slug: "my-page", name: "Design systems" },
      { href: "#", slug: "my-page", name: "Pricing" },
      { href: "#", slug: "my-page", name: "Customers" },
      { href: "#", slug: "my-page", name: "Security" },
    ],
  },
  {
    title: "Resources",
    items: [
      { href: "#", slug: "my-page", name: "Best practices" },
      { href: "#", slug: "my-page", name: "Support" },
      { href: "#", slug: "my-page", name: "Developers" },
      { href: "#", slug: "my-page", name: "Learn design" },
      { href: "#", slug: "my-page", name: "What's new" },
      { href: "#", slug: "my-page", name: "Releases" },
    ],
  },
  {
    title: "Aide",
    items: [
      { href: "#", slug: "my-page", name: "Discussion Forums" },
      { href: "#", slug: "my-page", name: "Code of Conduct" },
      { href: "#", slug: "my-page", name: "Community Resources" },
      { href: "#", slug: "my-page", name: "Contributing" },
      { href: "#", slug: "my-page", name: "Concurrent Mode" },
      { href: "#", slug: "my-page", name: "API Reference" },
    ],
  },
];

export type { IItemMenu as IItem };
