import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import userAuth from "@/helpers/auth";
import { Logo, Button, ButtonSwitchTheme } from "@scanow/ui";
import { useCookies } from "react-cookie";
import { DropdownNotification, DropdownUser } from "./";
import SearchForm from "@/components/Shared/Form/Search";
import { Drawer } from "./Drawer";
import { IoScanSharp } from "react-icons/io5";

interface IMenu {}
 
export const Menu = ({}: IMenu) => {
  const auth = userAuth();
  const router = useRouter();
  const [cookie, setCookie, removeCookie] = useCookies(["accessToken"]);
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (auth) {
      setIsAuth(true);
    }
  }, []);

  // const { data, loading, error } = useQuery<ISettingsData>(GET_DASHBOARD_DATA, {
  //   variables: { company_id: auth.user?.company_id || "" },
  //   skip: !auth.isLogged,
  // });
  const handleClick = () => {
    router.push(`/admin/checking`);
  };

  return (
    <>
      <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
        <Logo width="w-[150px]" />
        {/*<SearchForm />*/}
      </div>
      <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-2">
        <Link href={"/admin/checking"}>
          <a
            title="Nouveau mouvement"
            className="cursor-pointer relative flex justify-center items-center rounded-full w-9 h-9 bg-secondary-600 hover:bg-opacity-90 text-white  duration-300 transition ease-in-out px-2 py-1 text-xs"
          >
            <IoScanSharp />
          </a>
        </Link>
        <ButtonSwitchTheme />
        <div className="desktop-view items-center flex space-x-2  ">
          {isAuth && (
            <>
              <hr className="md:block h-8 border-l border border-neutral-200 dark:border-neutral-800"></hr>
              {/*<DropdownNotification  />*/}
              <DropdownUser />
            </>
          )}
        </div>
        <span className="lg:hidden flex items-center">
          <Drawer />
        </span>
      </div>
    </>
  );
};
