import { IsActive, SidebarNavigation } from "@/components/Navigation";
import { useAuth } from "@/helpers/contexts/auth";
import { IItemMenu } from "@/interfaces/navigation";
import classNames from "classnames";
//import { LinkActive } from "@/components/Shared/Links/Active";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useLicense from "@/helpers/license/useLicense";

export interface ISidebar {
  className?: string;
}

interface IMenuItemActive extends IItemMenu {
  active: boolean;
}

export const Sidebar = ({ className = "" }: ISidebar) => {
  const [menu, setMenu] = useState<IMenuItemActive[]>([]);
  const router = useRouter();
  const { auth } = useAuth();
  const license = useLicense();

  useEffect(() => {
    if (license.licenseLoading) return;
    const menu = SidebarNavigation();
    const pathname = router.pathname;
    const newMenu = menu
      .map((item) => {
        return {
          ...item,
          active: IsActive(pathname, item),
        };
      })
      .filter((item) => {
        return item.min_role ? item.min_role >= auth.sonar_role_weight : true;
      })
      .filter((item) => {
        if (item.allowedApplications != null) {
          return license.licenseAllowedApplications.find((app) => item.allowedApplications?.includes(app)) != null;
        }
        return true;
      });
    setMenu(newMenu);
  }, [router.pathname, license.licenseLoading]);

  return (
    <ul
      className={`flex flex-col px-2 lg:p-4 bg-white dark:bg-neutral-900 rounded-xl ${className} `}
    >
      {menu.map((item) => (
        <li key={item.name}>
          <Link
            href={{
              pathname: item.href || undefined,
            }}
            target={item.targetBlank ? "_blank" : undefined}
            rel="noopener noreferrer"
          >
            <a
              className={`flex items-center p-3 mb-1 dark:text-neutral-200 font-normal lg:font-medium transition duration-300 ease-in-out hover:bg-indigo-600 hover:text-white dark:hover:bg-neutral-800 rounded-md ${
                item.active &&
                "active bg-secondary-600 text-white dark:bg-neutral-800"
              }`}
              /* className={classNames(
                " flex items-center p-3 mb-1 dark:text-neutral-200 font-normal lg:font-medium transition duration-300 ease-in-out hover:bg-indigo-600 hover:text-white dark:hover:bg-neutral-800 rounded-md",
                item.active && "active bg-secondary-600 text-white dark:bg-neutral-800"
              )}*/
            >
              <div className="flex items-center justify-center flex-shrink-0 ">
                {item.icon}
              </div>
              <div className="ml-3 text-sm md:text-xs 2xl:text-sm">
                <span>{item.name}</span>
              </div>
            </a>
          </Link> 
        </li>
      ))}
    </ul>
  );
};
