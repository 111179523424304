import { useQuery } from '@apollo/client';
import { useAuth } from '@/helpers/contexts/auth';
import { useEffect, useState } from 'react';
import {
  SCANOW_INVENTORY_ASSET_LICENSE_QUERY,
  IScanowHelpdeskLicense,
  IScanowLicense,
  IScanowLicenseSelector,
  IScanowAssetLicense,
  IScanowInventoryLicense,
} from '@scanow/helpers';
import {APPLICATIONS} from "@scanow/config";

const inventoryLicenseAllowedLicense = [APPLICATIONS.Inventory];
const assetLicenseAllowedLicense = [APPLICATIONS.Asset];


export default function useLicense() {
  const { auth } = useAuth();
  const { data, loading, error } = useQuery(SCANOW_INVENTORY_ASSET_LICENSE_QUERY, {
    variables: {
      company_id: auth?.user?.company_id || '',
    },
    skip: !auth?.user?.company_id,
  });

  const [isInventoryLoaded, setIsInventoryLoaded] = useState(false);
  const [isAssetLoaded, setIsAssetLoaded] = useState(false);

  // null assumes that the user has no licence, or that the query is still loading
  const [assetLicense, setAssetLicense] = useState<IScanowLicenseSelector<IScanowAssetLicense> | null>(null);
  const [inventoryLicense, setInventoryLicense] = useState<IScanowLicenseSelector<IScanowInventoryLicense> | null>(null);

  const [assetRemainingUsers, setAssetRemainingUsers] = useState<number | null>(null);
  const [inventoryRemainingItems, setInventoryRemainingItems] = useState<number | null>(null);

  const [assetLicenseEndDate, setAssetLicenseEndDate] = useState<Date | null>(null);
  const [inventoryLicenseEndDate, setInventoryLicenseEndDate] = useState<Date | null>(null);

  const [assetLicenseEndInDays, setAssetLicenseEndInDays] = useState<number | null>(null);
  const [inventoryLicenseEndInDays, setInventoryLicenseEndInDays] = useState<number | null>(null);

  const [assetCanCreateUsers, setAssetCanCreateUsers] = useState<boolean | null>(null);
  const [inventoryCanCreateItems, setInventoryCanCreateItems] = useState<boolean | null>(null);

  const [assetOptionReporting, setAssetOptionReporting] = useState<boolean | null>(null);
  const [inventoryOptionReporting, setInventoryOptionReporting] = useState<boolean | null>(null);

  const [licenseAllowedApplications, setLicenseAllowedApplications] = useState<APPLICATIONS[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const licenses = data.administration_licence;
    const assetLicenses = licenses.filter((licence) => assetLicenseAllowedLicense.includes(licence.licence.application)) as IScanowLicenseSelector<IScanowAssetLicense>[];

    if (assetLicenses.length === 0) {
      setAssetLicense(null);
      return;
    }

    setLicenseAllowedApplications((prev) => [...prev, APPLICATIONS.Asset]);

    const lastToExpire = assetLicenses.sort((a, b) => {
      const aDate = new Date(a.expires_at);
      const bDate = new Date(b.expires_at);
      return aDate.getTime() - bDate.getTime();
    })[0];

    setAssetLicense(lastToExpire);
    const remainingUsers = lastToExpire.licence.max_users - data.administration_company_by_pk.asset_users_aggregate.aggregate.count;

    setAssetRemainingUsers(Math.max(remainingUsers, 0));
    setAssetLicenseEndDate(new Date(lastToExpire.expires_at));
    setAssetLicenseEndInDays(Math.round((new Date(lastToExpire.expires_at).getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

    const isLicenseValid = new Date(lastToExpire.expires_at).getTime() > new Date().getTime();
    setAssetCanCreateUsers(isLicenseValid && remainingUsers > 0);

    setAssetOptionReporting(lastToExpire.licence.options.reporting);

    setIsAssetLoaded(true);
  }, [data]);


  useEffect(() => {
    if (!data) {
      return;
    }
    const licenses = data.administration_licence;
    const inventoryLicenses = licenses.filter((licence) => inventoryLicenseAllowedLicense.includes(licence.licence.application)) as IScanowLicenseSelector<IScanowInventoryLicense>[];

    if (inventoryLicenses.length === 0) {
      setInventoryLicense(null);
      return;
    }

    setLicenseAllowedApplications((prev) => [...prev, APPLICATIONS.Inventory]);

    const lastToExpire = inventoryLicenses.sort((a, b) => {
      const aDate = new Date(a.expires_at);
      const bDate = new Date(b.expires_at);
      return aDate.getTime() - bDate.getTime();
    })[0];

    setInventoryLicense(lastToExpire);

    const remainingItems = lastToExpire.licence.max_items - data.administration_company_by_pk.sonar_items_aggregate.aggregate.count;

    setInventoryRemainingItems(Math.max(remainingItems, 0));
    setInventoryLicenseEndDate(new Date(lastToExpire.expires_at));
    setInventoryLicenseEndInDays(Math.round((new Date(lastToExpire.expires_at).getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

    const isLicenseValid = new Date(lastToExpire.expires_at).getTime() > new Date().getTime();
    setInventoryCanCreateItems(isLicenseValid && remainingItems > 0);

    setInventoryOptionReporting(lastToExpire.licence.options.reporting);

    setIsInventoryLoaded(true);

  }, [data]);


  return {
    assetLicense,
    inventoryLicense,
    assetRemainingUsers,
    inventoryRemainingItems,
    assetLicenseEndDate,
    inventoryLicenseEndDate,
    assetLicenseEndInDays,
    inventoryLicenseEndInDays,
    assetCanCreateUsers,
    inventoryCanCreateItems,
    assetOptionReporting,
    inventoryOptionReporting,
    licenseAllowedApplications,

    licenseLoading: !isAssetLoaded || !isInventoryLoaded,
  };

}